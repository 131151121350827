import reservoirService from "data/ReservoirService";
import { AttributeModel } from "data/store/models";
import { SimpleNft } from "./INft";
import contractsService from "data/ContractsService";
import { SupportedChainIds } from "clients/verisModule";

export class Attribute {
  static model = AttributeModel;

  key: string;
  value: string;
  nft: SimpleNft & { chainId: SupportedChainIds };

  constructor(nft: SimpleNft & { chainId: SupportedChainIds }, key: string, value: string) {
    this.nft = nft;
    this.key = key;
    this.value = value;
  }

  private get cacheId() {
    return (
      this.nft.collection + this.nft.tokenId + this.key + this.value
    );
  }

  private get extraData(): Promise<{ floorPrice: bigint; rarity: bigint }> {
    return new Promise<{ floorPrice: bigint; rarity: bigint }>(
      async (resolve) => {
        const { rarity, floorPrice } = Attribute.model.findByIdLeanNullSafe(
          this.cacheId
        );

        if (rarity !== undefined && floorPrice !== undefined) {
          resolve({
            rarity: rarity as bigint,
            floorPrice: floorPrice as bigint,
          });
        } else {
          const [attributesFromReservoir, totalSupply] = await Promise.all([
            reservoirService.getAttributes(this.nft),
            contractsService.totalSupply(this.nft.collection, this.nft.chainId),
          ]);

          const attribute = attributesFromReservoir.find((attribute) => {
            attribute.key === this.key && attribute.value === this.value;
          });

          if (attribute) {
            const extraData = {
              floorPrice:
                attribute.floorAskPrices.length > 0
                  ? BigInt(attribute.floorAskPrices[0] * 1e18)
                  : BigInt(0),
              rarity:
                (BigInt(attribute.tokenCount) * BigInt(10000)) / totalSupply,
            };

            Attribute.model.save(this.cacheId, extraData);

            resolve(extraData);
          } else {
            resolve({
              floorPrice: BigInt(0),
              rarity: BigInt(0),
            });
          }
        }
      }
    );
  }

  get rarity(): Promise<bigint> {
    return new Promise<bigint>(async (resolve) => {
      const { rarity } = await this.extraData;

      resolve(rarity);
    });
  }

  get floorPrice(): Promise<bigint> {
    return new Promise<bigint>(async (resolve) => {
      const { floorPrice } = await this.extraData;

      resolve(floorPrice);
    });
  }
}
