import { SupportedChainIds } from "clients/verisModule";
import { SimpleNft } from "logic/types/nft/INft";
import { Address } from "viem";

export function getNftId(collection: Address, tokenId: string, chainId: SupportedChainIds): string {
  return `${chainId}-${collection}-${tokenId}`;
}

export function getNftFromNftId(id: string): SimpleNft & { chain: SupportedChainIds } {
  if (!id.includes("-")) {
    throw new Error(`Invalid NFT id: ${id}`);
  }

  const data = id.split("-");

  if (data.length !== 3) {
    throw new Error(`Invalid NFT id: ${id}`);
  }

  if (data[1].length !== 42 || !data[1].startsWith("0x")) {
    throw new Error(`Invalid collection: ${data[0]}`);
  }

  if (data[2].length === 0) {
    throw new Error(`Invalid token id: ${data[1]}`);
  }

  return {
    chain: Number(data[0]) as SupportedChainIds,
    collection: data[1] as Address,
    tokenId: data[2],
  };
}
