import { NetworkConfig } from "config/app.config";
import { INft, SimpleNft } from "./types/nft/INft";
import { Address } from "@unlockdfinance/verislabs-web3";
import {
  areTheSameNft,
  equalIgnoreCase,
} from "@unlockdfinance/verislabs-web3/utils";
import { OwnedNft } from "alchemy-sdk";
import { WalletType } from "contracts/types";
import { CollectionCategory } from "./types/collection/ICollection";
import collectionsModule from "./CollectionsModule";
import {
  SupportedChainIds,
  SupportedChainNames,
  externalWalletModule,
  verisModule,
} from "clients/verisModule";
import unlockdWalletModule from "./UnlockdWalletModule";
import areNftsDeposited from "./helpers/nfts/areNftsDeposited";
import sortNftsByDeposited from "./helpers/sortNftsByDeposited";
import NftFactory from "./types/nft/NftFactory";
import alchemyService from "data/AlchemyService";
import Chain from "./types/chain/Chain";

export enum OrderByOption {
  VALUATION = "valuation",
  AV_TO_BORROW = "av-to-borrow",
}

export enum SortOption {
  ASC = "asc",
  DESC = "desc",
}

export type FilterSelected = {
  assetOrigin?: WalletType;
  currency?: string;
  categories?: CollectionCategory[];
  collection?: Address;
  orderBy?: OrderByOption;
  sort?: SortOption;
};

export type OptionsGetNfts = FilterSelected & {
  avoidDeposited?: boolean;
  pageSize?: number;
};

class NftsModule {
  private ownedNftsCache: Map<
    string,
    {
      nfts: (SimpleNft & { isDeposited: boolean; owner: Address, chainId: SupportedChainIds })[];
      fetchCompleted: boolean;
    }
  > = new Map();
  private contractsSupportedForNetwork: Map<SupportedChainNames, Address[]> =
    new Map();

  constructor(networks: NetworkConfig[]) {
    networks.forEach((network) => {
      this.contractsSupportedForNetwork.set(
        network.CHAIN.name,
        network.COLLECTIONS.map(({ address }) => address)
      );
    });
  }

  private getContractsSupported(chainId: SupportedChainIds) {
    const contractsSupported = this.contractsSupportedForNetwork.get(
      Chain.get(chainId).name
    );

    if (!contractsSupported) {
      throw new Error(
        `Contracts not found for chain ${chainId}`
      );
    }

    return contractsSupported;
  }

  private generateCacheKey(address: Address, collection: Address, chainId: SupportedChainIds) {
    return `${chainId}-${address}-${collection}`;
  }

  private getNftsFromCache = (
    address: Address,
    collection: Address,
    chainId: SupportedChainIds
  ): (SimpleNft & { isDeposited: boolean; owner: Address, chainId: SupportedChainIds })[] | null => {
    const key = this.generateCacheKey(address, collection, chainId);

    return this.ownedNftsCache.get(key)?.nfts || null;
  };

  private saveNftsToCache = (
    owner: Address,
    collection: Address,
    nfts: (SimpleNft & { isDeposited: boolean; owner: Address, chainId: SupportedChainIds })[],
    fetchCompleted?: boolean
  ) => {
    if (nfts.length === 0) return;

    const key = this.generateCacheKey(owner, collection, nfts[0].chainId);

    nfts.forEach((nft) => {
      const cache = this.ownedNftsCache.get(key);

      if (cache) {
        if (
          !cache.nfts.some((nftFromCache) => areTheSameNft(nftFromCache, nft))
        ) {
          this.ownedNftsCache.set(key, {
            nfts: cache.nfts.concat(nft),
            fetchCompleted:
              fetchCompleted !== undefined
                ? fetchCompleted
                : cache.fetchCompleted,
          });
        }
      } else {
        this.ownedNftsCache.set(key, {
          nfts: [nft],
          fetchCompleted: fetchCompleted !== undefined ? fetchCompleted : false,
        });
      }
    });
  };

  removeNftFromCache(
    walletAddress: Address,
    collection: Address,
    tokenId: string,
    chainId: SupportedChainIds
  ) {
    const key = this.generateCacheKey(walletAddress, collection, chainId);

    const cache = this.ownedNftsCache.get(key);

    if (cache) {
      this.ownedNftsCache.set(key, {
        nfts: cache.nfts.filter((nft) => nft.tokenId !== tokenId),
        fetchCompleted: cache.fetchCompleted,
      });
    }
  }

  removeCollectionCollectionFromCache(
    walletAddress: Address,
    collection: Address,
    chainId: SupportedChainIds
  ) {
    const key = this.generateCacheKey(walletAddress, collection, chainId);

    this.ownedNftsCache.delete(key);
  }

  addNftToCache(
    walletAddress: Address,
    nft: {
      collection: Address;
      tokenId: string;
      owner: Address;
      isDeposited: boolean;
      chainId: SupportedChainIds;
    }
  ) {
    this.saveNftsToCache(walletAddress, nft.collection, [nft]);
  }

  updateNftFromCache(
    walletAddress: Address,
    collection: Address,
    tokenId: string,
    chainId: SupportedChainIds,
    { isDeposited, owner: newOwner }: { isDeposited?: boolean; owner?: Address }
  ) {
    const key = this.generateCacheKey(walletAddress, collection, chainId);

    const cache = this.ownedNftsCache.get(key);

    if (cache) {
      const index = cache.nfts.findIndex((_nft) =>
        areTheSameNft(_nft, { collection, tokenId })
      );
      if (index !== -1) {
        const nftsCopy = [...cache.nfts];

        if (isDeposited !== undefined) {
          nftsCopy[index].isDeposited = isDeposited;
        }

        if (newOwner) {
          nftsCopy[index].owner = newOwner;
        }

        this.ownedNftsCache.set(key, {
          nfts: nftsCopy,
          fetchCompleted: cache.fetchCompleted,
        });
      }
    }

    if (newOwner !== undefined) {
      const key = this.generateCacheKey(newOwner, collection, chainId);

      const cache = this.ownedNftsCache.get(key);

      const newNfts = [
        {
          collection,
          tokenId,
          isDeposited: isDeposited || false,
          owner: newOwner,
          chainId,
        },
      ];

      if (cache) {
        newNfts.push(...cache.nfts);
      }

      this.ownedNftsCache.set(key, {
        nfts: newNfts,
        fetchCompleted: cache?.fetchCompleted || false,
      });
    }
  }

  cleanCache() {
    this.ownedNftsCache.clear();
  }

  private getCachedNftsAndCollections(
    collectionToGet: Address[],
    chainId: SupportedChainIds,
    extWalletAddress?: Address,
    uWalletAddress?: Address,
    avoidDeposited?: boolean
  ) {
    const collectionsToFetchFromUWallet: Address[] = [];
    const uWalletNftsFromCache: (SimpleNft & {
      isDeposited: boolean;
      owner: Address;
      chainId: SupportedChainIds
    })[] = [];
    const collectionsToFetchFromExtWallet: Address[] = [];
    const extWalletNftsFromCache: (SimpleNft & {
      isDeposited: boolean;
      owner: Address;
      chainId: SupportedChainIds
    })[] = [];

    collectionToGet.forEach((collection) => {
      if (extWalletAddress) {
        let nfts = this.getNftsFromCache(extWalletAddress, collection, chainId);

        if (nfts) {
          if (avoidDeposited) {
            nfts = nfts.filter((nft) => !nft.isDeposited);
          }

          extWalletNftsFromCache.push(...nfts);
        } else {
          collectionsToFetchFromExtWallet.push(collection);
        }
      }

      if (uWalletAddress) {
        let nfts = this.getNftsFromCache(uWalletAddress, collection, chainId);

        if (nfts) {
          if (avoidDeposited) {
            nfts = nfts.filter((nft) => !nft.isDeposited);
          }

          uWalletNftsFromCache.push(...nfts);
        } else {
          collectionsToFetchFromUWallet.push(collection);
        }
      }
    });

    return {
      collectionsToFetchFromUWallet,
      collectionsToFetchFromExtWallet,
      uWalletNftsFromCache,
      extWalletNftsFromCache,
    };
  }

  private listCollectionsToGet(chainId: SupportedChainIds, options?: OptionsGetNfts) {
    if (
      !options ||
      (!options.currency && !options.collection && !options.categories)
    ) {
      return this.getContractsSupported(chainId);
    } else if (options.collection) {
      return [options.collection];
    }

    let collections = collectionsModule.getCollections(chainId);

    if (options?.currency) {
      collections = collections.filter((collection) =>
        equalIgnoreCase(
          collection.currenciesSupported[0].address,
          options.currency!
        )
      );
    }

    if (options?.categories) {
      collections = collections.filter((collection) =>
        options.categories!.includes(collection.category)
      );
    }

    return collections.map(({ address }) => address);
  }

  private filterUnikuraNotSupportedNfts(nfts: OwnedNft[]) {
    if (verisModule.isMainnetConnected) {
      const generalNfts: OwnedNft[] = [];
      let unikuraNfts: OwnedNft[] = [];

      const unikuraAddress = verisModule.mainnetNetworkConfig.COLLECTIONS.find(
        (collection) => collection.name === "Unikura Collectibles"
      )?.address;

      if (unikuraAddress) {
        nfts.forEach((nft) => {
          if (equalIgnoreCase(nft.contract.address, unikuraAddress)) {
            unikuraNfts.push(nft);
          } else {
            generalNfts.push(nft);
          }
        });

        unikuraNfts = unikuraNfts.filter(
          (nft) =>
            !!nft.raw.metadata?.attributes &&
            (
              nft.raw.metadata.attributes as {
                trait_type: string;
                value: any;
              }[]
            ).some(
              (attribute) =>
                equalIgnoreCase(attribute.trait_type, "category") &&
                equalIgnoreCase(attribute.value, "watches")
            )
        );

        return generalNfts.concat(unikuraNfts);
      }
    }

    return nfts;
  }

  async areNfts({
    assetOrigin,
    currency,
    avoidDeposited,
    chainId
  }: {
    assetOrigin?: WalletType;
    currency?: Address;
    avoidDeposited?: boolean;
    chainId: SupportedChainIds
  }) {
    const isExtWalletRequested =
      assetOrigin === undefined || assetOrigin === WalletType.BASIC;

    const isUWalletRequested =
      assetOrigin === undefined || assetOrigin === WalletType.SMART;

    const extWallet = externalWalletModule.address!;
    let uWallet: Address | null = null;

    if (isUWalletRequested) {
      uWallet = await unlockdWalletModule.getSafeWallet(extWallet, chainId);
    }

    const collectionsToGet = this.listCollectionsToGet(chainId, { currency });

    const {
      collectionsToFetchFromExtWallet,
      collectionsToFetchFromUWallet,
      extWalletNftsFromCache,
      uWalletNftsFromCache,
    } = this.getCachedNftsAndCollections(
      collectionsToGet,
      chainId,
      isExtWalletRequested ? extWallet : undefined,
      isUWalletRequested && uWallet ? uWallet : undefined,
      avoidDeposited
    );

    if (extWalletNftsFromCache.length > 0 || uWalletNftsFromCache.length > 0) {
      return true;
    }

    if (collectionsToFetchFromExtWallet.length !== 0) {
      const options = {
        contractAddresses: collectionsToFetchFromExtWallet,
        pageSize: 100,
      };

      const nftsFromExtWallet = this.filterUnikuraNotSupportedNfts(
        await alchemyService.getNftsForOwnerLoop(extWallet, chainId, options, true)
      );

      if (nftsFromExtWallet.length > 0) {
        return true;
      }
    }

    if (collectionsToFetchFromUWallet.length !== 0) {
      const options = {
        contractAddresses: collectionsToFetchFromUWallet,
        pageSize: 100,
      };

      const nftsFromUWallet = this.filterUnikuraNotSupportedNfts(
        await alchemyService.getNftsForOwnerLoop(uWallet!, chainId, options, true)
      );

      if (nftsFromUWallet.length > 0) {
        if (!avoidDeposited) {
          return true;
        } else {
          const areDepositedArray = await areNftsDeposited(
            nftsFromUWallet.map((nft) => ({
              collection: nft.contract.address as Address,
              tokenId: nft.tokenId,
            })),
            chainId
          );

          if (areDepositedArray.some((isDeposited) => !isDeposited)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  async getNfts(
    options: OptionsGetNfts,
    chainId: SupportedChainIds,
    abortSignal?: AbortSignal
  ): Promise<INft[] | void> {
    const pageSize = options?.pageSize || 100;

    const isExtWalletRequested =
      options.assetOrigin === undefined ||
      options.assetOrigin === WalletType.BASIC;

    const isUWalletRequested =
      options.assetOrigin === undefined ||
      options.assetOrigin === WalletType.SMART;

    const extWallet = externalWalletModule.address!;
    let uWallet: Address | null = null;

    if (isUWalletRequested) {
      uWallet = await unlockdWalletModule.getSafeWallet(extWallet, chainId);
    }

    const collectionToGet = this.listCollectionsToGet(chainId, options);

    const {
      collectionsToFetchFromExtWallet,
      collectionsToFetchFromUWallet,
      extWalletNftsFromCache,
      uWalletNftsFromCache,
    } = this.getCachedNftsAndCollections(
      collectionToGet,
      chainId,
      isExtWalletRequested ? extWallet : undefined,
      isUWalletRequested && uWallet ? uWallet : undefined,
      options?.avoidDeposited
    );

    let nftsFromCache = extWalletNftsFromCache.concat(uWalletNftsFromCache);

    const alchemyNfts: (OwnedNft & { owner: Address })[] = [];

    if (collectionsToFetchFromExtWallet.length !== 0 && !abortSignal?.aborted) {
      const options = {
        contractAddresses: collectionsToFetchFromExtWallet,
        pageSize,
      };

      const nftsFromExtWallet = this.filterUnikuraNotSupportedNfts(
        await alchemyService.getNftsForOwnerLoop(extWallet, chainId, options, true)
      );

      alchemyNfts.push(
        ...nftsFromExtWallet.map((nft) => ({ ...nft, owner: extWallet }))
      );
    }

    if (collectionsToFetchFromUWallet.length !== 0 && !abortSignal?.aborted) {
      const options = {
        contractAddresses: collectionsToFetchFromUWallet,
        pageSize,
      };

      const nftsFromUWallet = this.filterUnikuraNotSupportedNfts(
        await alchemyService.getNftsForOwnerLoop(uWallet!, chainId, options, true)
      );

      alchemyNfts.push(
        ...nftsFromUWallet.map((nft) => ({ ...nft, owner: uWallet! }))
      );
    }

    let _alchemyNfts: INft[] = [];

    if (alchemyNfts.length > 0 && !abortSignal?.aborted) {
      const _areNftsDeposited = await areNftsDeposited(
        alchemyNfts.map((nft) => ({
          collection: nft.contract.address as Address,
          tokenId: nft.tokenId,
        })),
        chainId
      );

      _alchemyNfts = alchemyNfts.map((_nft, index) => {
        const input = {
          chainId,
          collection: _nft.contract.address as Address,
          tokenId: _nft.tokenId,
          isDeposited: _areNftsDeposited[index],
          owner: _nft.owner,
        };

        const nft = NftFactory.create(input);

        this.saveNftsToCache(_nft.owner, nft.collection, [input], true);

        return nft;
      });

      if (options?.avoidDeposited) {
        _alchemyNfts = _alchemyNfts.filter(({ isDeposited }) => !isDeposited);
      }
    }

    // REGISTER EMPTY COLLECTIONS FETCHED FROM EXTERNAL WALLET
    collectionsToFetchFromExtWallet.forEach((collection) => {
      const cache = this.getNftsFromCache(extWallet, collection, chainId);

      if (!cache) {
        this.saveNftsToCache(extWallet, collection, [], true);
      }
    });

    // REGISTER EMPTY COLLECTIONS FETCHED FROM UWALLET
    collectionsToFetchFromUWallet.forEach((collection) => {
      const cache = this.getNftsFromCache(uWallet!, collection, chainId);

      if (!cache) {
        this.saveNftsToCache(uWallet!, collection, [], true);
      }
    });

    const _nftsFromCache = nftsFromCache.map((nft) =>
      NftFactory.create({
        ...nft,
        collection: nft.collection,
      })
    );

    if (!abortSignal?.aborted) {
      return await this.sortNfts(_nftsFromCache.concat(_alchemyNfts), options);
    }
  }

  private async sortNfts(
    nfts: INft[],
    options: OptionsGetNfts
  ): Promise<INft[]> {
    if (options.orderBy === undefined || options.sort === undefined) {
      return sortNftsByDeposited(nfts);
    }

    const methodToCall =
      options.orderBy === OrderByOption.AV_TO_BORROW
        ? "getAvToBorrowInUsd"
        : "getValuationInUsd";

    const nftsWithValue: { nft: INft; value: bigint }[] = await Promise.all(
      nfts.map(
        (nft) =>
          new Promise<{ nft: INft; value: bigint }>(async (resolve, reject) => {
            try {
              const value = await nft[methodToCall]();

              resolve({ nft, value });
            } catch (err) {
              reject(err);
            }
          })
      )
    );

    return nftsWithValue
      .sort((a, b) => {
        return a.nft.isDeposited !== b.nft.isDeposited
          ? Number(b.nft.isDeposited) - Number(a.nft.isDeposited)
          : options.sort === SortOption.ASC
            ? Number(a.value) - Number(b.value)
            : Number(b.value) - Number(a.value);
      })
      .map(({ nft }) => nft);
  }
}

export default new NftsModule(verisModule.networks);
