import { determineAuctionStatus } from "../../helpers";
import { Loan } from "../loan/Loan";
import { auctionContract, marketContract } from "../../../contracts";
import { MarketItemType } from "../../../contracts/MarketContract";
import { Address } from "viem";
import { Bid } from "./Bid";
import UnlockdService from "../../../data/UnlockdService";
import { encodeEventTopics, decodeEventLog } from "viem";
import unlockdWalletModule from "../../UnlockdWalletModule";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import {
  externalWalletModule,
  verisModule,
} from "../../../clients/verisModule";
import {
  areTheSameNft,
  equalIgnoreCase,
} from "@unlockdfinance/verislabs-web3/utils";
import { INft } from "../nft/INft";
import { calculateLoanValuation, calculateLtv } from "logic/helpers/math";
import NftPrices from "../nft/NftPrices";
import { LoanModel, MarketItemModel, NftModel } from "data/store/models";
import getMaximumBidFromReservoir from "logic/helpers/getMaximumBidFromReservoir";
import currenciesModule from "logic/CurrenciesModule";
import NftsModule from "logic/NftsModule";
import contractsService from "data/ContractsService";
import { TheGraph } from "data/TheGraphService";

export enum MarketItemAuctionStatus {
  TO_REPAY,
  TO_REDEEM,
  TO_CLAIM,
  TO_FIRST_BID,
  TO_BID,
  BIDDED,
  REDEEM_FINISHED,
  BID_FINISHED,
  LOADING_CLAIM,
  CLAIMED,
}

export class MarketItem {
  static model = MarketItemModel;
  private _id?: Address;
  readonly loan: Loan;
  readonly status: TheGraph.MarketItemStatus;
  readonly type: MarketItemType;
  readonly nft: INft;
  readonly bids: Bid[];
  readonly biddingEnd: number;
  readonly lastStatusChangedTimestamp?: number;

  constructor({
    biddingEnd,
    bids,
    id,
    lastStatusChangedTimestamp,
    status,
    type,
    nft,
    loan,
  }: {
    loan: Loan;
    nft: INft;
    type: MarketItemType;
    id?: Address;
    bids: Bid[];
    status: TheGraph.MarketItemStatus;
    biddingEnd: number;
    lastStatusChangedTimestamp?: number;
  }) {
    this.status = status;
    this.loan = loan;
    this.type = type;
    this._id = id;
    this.nft = nft;
    this.bids = bids;
    this.biddingEnd = biddingEnd;
    this.status = status;

    this.lastStatusChangedTimestamp = lastStatusChangedTimestamp;
  }

  get id(): Address | undefined {
    return this._id;
  }

  set id(id: Address) {
    this._id = id;
  }

  get owner(): Address {
    return this.loan.borrower;
  }

  get bidder(): Address | null {
    return this.bids.at(-1)?.bidder || null;
  }

  get isAuctionedOffChain(): boolean {
    return (
      this.type === MarketItemType.TYPE_LIQUIDATION_AUCTION &&
      this.bids.length === 0
    );
  }

  get latestBid(): bigint | null {
    return this.bids?.at(-1)?.amount || null;
  }

  get auctionStatus(): MarketItemAuctionStatus | null {
    return determineAuctionStatus(
      this.type,
      this.loan.borrower,
      externalWalletModule.address,
      this.biddingEnd!,
      this.bidder,
      !!this.bidder
    );
  }

  get isBuyNowAvailable(): boolean {
    const auctionStatus = this.auctionStatus;

    return (
      this.hasBuyNowAsOption &&
      !this.isOwnItem &&
      !this.hasAuctionFinished &&
      auctionStatus !== MarketItemAuctionStatus.TO_REDEEM &&
      auctionStatus !== MarketItemAuctionStatus.TO_REPAY &&
      auctionStatus !== MarketItemAuctionStatus.CLAIMED &&
      auctionStatus !== MarketItemAuctionStatus.TO_CLAIM
    );
  }

  get futureNfts(): INft[] {
    return this.loan.nfts.filter((nft) => !areTheSameNft(nft, this.nft));
  }

  private get aggLoanPrice(): Promise<bigint> {
    return new Promise<bigint>(async (resolve) => {
      resolve(
        this.futureNfts.length > 0
          ? calculateLoanValuation(
            await Promise.all(
              this.futureNfts.map(
                (nft) =>
                  new Promise<NftPrices>(async (resolve) =>
                    resolve(await nft.prices)
                  )
              )
            )
          )
          : BigInt(0)
      );
    });
  }

  private get aggLtv(): Promise<bigint> {
    return new Promise<bigint>(async (resolve) => {
      resolve(
        this.futureNfts.length > 0
          ? calculateLtv(
            await Promise.all(
              this.futureNfts.map(
                (nft) =>
                  new Promise<NftPrices>(async (resolve) =>
                    resolve(await nft.prices)
                  )
              )
            )
          )
          : BigInt(0)
      );
    });
  }

  // THE BUY NOW PRICE THAT IS RETURNED WILL BE THE VALUE RETURNED BY THE CONTRACT
  // WITH A 1% INCREASE TO BE SURE THE IT COVERS THE DEBT
  get buyNowPrice(): Promise<bigint | undefined> {
    return new Promise<bigint | undefined>(async (resolve) => {
      if (
        this.id &&
        this.type !== MarketItemType.TYPE_LIQUIDATION_AUCTION &&
        this.type !== MarketItemType.TYPE_AUCTION
      ) {
        const { buyNowPrice: priceFromCache } =
          MarketItem.model.findByIdLeanNullSafe(this.key);

        if (priceFromCache) {
          resolve(priceFromCache as bigint);
        } else {
          const [aggValuation, aggLtv] = await Promise.all([
            this.aggLoanPrice,
            this.aggLtv,
          ]);

          const price =
            ((await marketContract.getBuyNowPrice(
              this.id,
              this.loan.underlyingAsset,
              aggValuation,
              aggLtv,
              this.nft.chainId
            )) *
              BigInt(101)) /
            BigInt(100);

          MarketItem.model.save(this.key, { buyNowPrice: price });

          resolve(price);
        }
      }
    });
  }

  get minBid(): Promise<bigint | undefined> {
    return new Promise<bigint | undefined>(async (resolve) => {
      if (this.type === MarketItemType.TYPE_FIXED_PRICE) {
        resolve(undefined);
      } else if (this.type === MarketItemType.TYPE_LIQUIDATION_AUCTION) {
        const { minBid: minBidFromCache } =
          MarketItem.model.findByIdLeanNullSafe(this.key);

        if (minBidFromCache !== undefined) {
          resolve(minBidFromCache as bigint);
        } else {
          const [nftValuation, aggValuation, aggLtv] = await Promise.all([
            this.nft.valuation,
            this.aggLoanPrice,
            this.aggLtv,
          ]);

          const minBid =
            ((await contractsService.getMinBidPriceAuction(
              this.loan.id,
              this.nft.assetId,
              nftValuation,
              aggValuation,
              aggLtv,
              this.nft.chainId,
              false,
            )) *
              BigInt(101)) /
            BigInt(100);

          MarketItem.model.save(this.key, { minBid });

          resolve(minBid);
        }
      } else {
        if (!this.id) {
          throw new Error("id is required for this type of marketItem min bid");
        }

        const { minBid: minBidFromCache } =
          MarketItem.model.findByIdLeanNullSafe(this.key);

        if (minBidFromCache !== undefined) {
          resolve(minBidFromCache as bigint);
        } else {
          const [aggValuation, aggLtv] = await Promise.all([
            this.aggLoanPrice,
            this.aggLtv,
          ]);

          const minBid =
            ((await contractsService.getMinBidPriceMarket(
              this.id,
              this.loan.underlyingAsset,
              aggValuation,
              aggLtv,
              this.currency.chain.id
            )) *
              BigInt(101)) /
            BigInt(100);

          MarketItem.model.save(this.key, { minBid });

          resolve(minBid);
        }
      }
    });
  }

  get reservoirBid(): Promise<bigint | null> {
    return new Promise<bigint | null>(async (resolve) => {
      const bidFromCache = MarketItem.model.findByIdLeanNullSafe(
        this.key
      ).reservoirBid;

      if (bidFromCache) {
        resolve(bidFromCache as bigint);
      } else {
        const reservoidBid = await getMaximumBidFromReservoir({ chainId: this.nft.chainId, collection: this.nft.collection, tokenId: this.nft.tokenId });

        MarketItem.model.save(this.key, {
          reservoirBid: reservoidBid?.amount,
        });

        resolve(reservoidBid as bigint | null);
      }
    });
  }

  private get currenciesAddressSupported() {
    return verisModule.getNetwork(this.nft.chainId).COLLECTIONS.find(({ address }) =>
      equalIgnoreCase(address, this.nft.collection)
    )!.currenciesSupported.map(({ address }) => address);
  }

  get currency() {
    const currencyAddressSupported = this.currenciesAddressSupported[0];

    const currency = currenciesModule.getErc20CurrencyByAddress(
      currencyAddressSupported,
      this.nft.chainId
    )!;

    return currency;
  }

  get key(): string {
    return this.isItemListed && this.id
      ? `${this.loan.currency.chain.id}-${this.id}`
      : `${this.loan.currency.chain.id}-${this.nft.collection + this.nft.tokenId + this.loan.id + this.biddingEnd}`;
  }

  get isOwnItem(): boolean {
    return externalWalletModule.address != undefined
      ? equalIgnoreCase(externalWalletModule.address, this.owner)
      : false;
  }

  get hasAuctionFinished(): boolean {
    return this.biddingEnd! <= Date.now();
  }

  get isAuctionAvailable(): boolean {
    return this.type !== MarketItemType.TYPE_FIXED_PRICE;
  }

  get isItemAuctioned(): boolean {
    return this.type === MarketItemType.TYPE_LIQUIDATION_AUCTION;
  }

  get isItemListed(): boolean {
    return this.type !== MarketItemType.TYPE_LIQUIDATION_AUCTION;
  }

  get hasBuyNowAsOption(): boolean {
    return (
      this.type !== MarketItemType.TYPE_AUCTION &&
      this.type !== MarketItemType.TYPE_LIQUIDATION_AUCTION
    );
  }

  async calculateMinInitialPayment(bidOrBuyAmount: bigint): Promise<bigint> {
    const [valuation, ltv] = await Promise.all([
      this.nft.valuation,
      this.nft.ltv,
    ]);

    const minInitialPayment =
      bidOrBuyAmount - (valuation * ltv) / BigInt(10000);

    const correctedMinPayment = (minInitialPayment * BigInt(101)) / BigInt(100);

    return minInitialPayment < BigInt(0)
      ? BigInt(0)
      : correctedMinPayment > bidOrBuyAmount
        ? bidOrBuyAmount
        : // WE INCREASE THE MINIMUM AMOUNT TO PAY 1%
        // TO BE SURE THAT AT THE MOMENT OF THE CLAIM
        // THE FUTURE DEBT WOULD BE HEALTHY
        correctedMinPayment;
  }

  async setBidPlaced(newBid: Bid) {
    this.bids.push(newBid);

    if (this.isItemListed) {
      const [aggValuation, aggLtv] = await Promise.all([
        this.aggLoanPrice,
        this.aggLtv,
      ]);

      MarketItem.model.save(this.key, {
        minBid: await contractsService.getMinBidPriceMarket(
          this.id!,
          this.loan.underlyingAsset,
          aggValuation,
          aggLtv,
          this.nft.chainId,
          true
        ),
      });
    } else {
      const [nftValuation, aggValuation, aggLtv] = await Promise.all([
        this.nft.valuation,
        this.aggLoanPrice,
        this.aggLtv,
      ]);

      MarketItem.model.save(this.key, {
        minBid: await contractsService.getMinBidPriceAuction(
          this.loan.id,
          this.nft.assetId,
          nftValuation,
          aggValuation,
          aggLtv,
          this.nft.chainId,
          true,
        ),
      });
    }
  }

  async placeABid(
    amountToPay: bigint,
    amountOfDebt: bigint,
    options: OptionsWriteMethod
  ): Promise<Address> {
    if (!this.isAuctionAvailable) {
      throw new Error("This item does not support bids");
    } else if (this.hasAuctionFinished) {
      throw new Error("This auction has finished");
    }

    if (this.isItemListed) {
      this.validateId();

      options?.onServerSignPending?.();

      const { data, signature } = await UnlockdService.get(this.currency.chain.id).getMarketSignature(
        this.nft
      );

      options.topicFilter = encodeEventTopics({
        abi: marketContract.abi,
        eventName: "MarketBid",
      })[0];

      const output = await marketContract.bid(
        this.id!,
        amountToPay,
        amountOfDebt,
        data,
        signature,
        options
      );

      // UPDATE DATA
      const newBid = new Bid(
        externalWalletModule.address!,
        amountToPay,
        amountOfDebt
      );

      await this.setBidPlaced(newBid);

      // DECODE THE OUTPUT
      if (!output.log) {
        throw new Error("log not found");
      }

      const topics = decodeEventLog({
        abi: marketContract.abi,
        data: output.log.data,
        topics: output.log.topics,
        eventName: "MarketBid",
      });

      return topics.args.orderId;
    } else {
      options?.onServerSignPending?.();

      const { data, signature } =
        await UnlockdService.get(this.currency.chain.id).getBidOnAuctionedSignature(
          this.loan.id,
          this.nft
        );

      options.topicFilter = encodeEventTopics({
        abi: auctionContract.abi,
        eventName: "AuctionBid",
      })[0];

      const output = await auctionContract.bid(
        amountToPay,
        amountOfDebt,
        data,
        signature,
        options
      );

      // UPDATE DATA
      const newBid = new Bid(
        externalWalletModule.address!,
        amountToPay,
        amountOfDebt
      );

      await this.setBidPlaced(newBid);

      // DECODE THE OUTPUT
      if (!output.log) {
        throw new Error("log not found");
      }

      const topics = decodeEventLog({
        abi: auctionContract.abi,
        data: output.log.data,
        topics: output.log.topics,
        eventName: "AuctionBid",
      });

      return topics.args.orderId;
    }
  }

  async buyNow(
    amountToPay: bigint,
    amountOfDebt: bigint,
    claimOnUWallet: boolean,
    options: OptionsWriteMethod
  ): Promise<Output<void>> {
    this.validateId();

    if (!claimOnUWallet && amountOfDebt > BigInt(0)) {
      throw new Error(
        "Must claim on uWallet because debt is related to the transaction"
      );
    } else if (claimOnUWallet && !unlockdWalletModule.unlockdAddress) {
      throw new Error("UWallet not detected on this user");
    }

    options?.onServerSignPending?.();

    const { data, signature } = await UnlockdService.get(this.currency.chain.id).getMarketSignature(
      this.nft
    );

    const output = await marketContract.buyNow(
      this.id!,
      amountToPay,
      amountOfDebt,
      claimOnUWallet,
      data,
      signature,
      options
    );

    // UPDATE DATA
    this.onMarketItemClaimedOrBought(claimOnUWallet, amountOfDebt > BigInt(0));

    return output;
  }

  async claim(options?: OptionsWriteMethod): Promise<Output<void>> {
    this.validateId();

    const debtOfBid = this.bids.at(-1)?.amountOfDebt;

    const hasBidWithDebt = debtOfBid !== undefined && debtOfBid > BigInt(0);

    const onUWallet = hasBidWithDebt || !!unlockdWalletModule.unlockdAddress;

    options?.onServerSignPending?.();

    if (this.isItemListed) {
      const { data, signature } = await UnlockdService.get(this.currency.chain.id).getMarketSignature(
        this.nft
      );

      const output = await marketContract.claim(
        onUWallet,
        this.id!,
        data,
        signature,
        options
      );

      // UPDATE DATA
      this.onMarketItemClaimedOrBought(onUWallet, hasBidWithDebt);

      return output;
    } else {
      const { data, signature } =
        await UnlockdService.get(this.currency.chain.id).getAuctionClaimSignature(
          this.loan.id,
          this.nft
        );

      const output = await auctionContract.finalize(
        onUWallet,
        this.id!,
        this.nft,
        data,
        signature,
        options
      );

      // UPDATE DATA
      this.onMarketItemClaimedOrBought(onUWallet, hasBidWithDebt);

      return output;
    }
  }

  onMarketItemClaimedOrBought(
    claimOnUWallet: boolean,
    createsANewLoan: boolean
  ) {
    const newOwner = claimOnUWallet
      ? unlockdWalletModule.unlockdAddress!
      : externalWalletModule.address!;

    // remove marketItem from cache
    MarketItem.model.deleteById(this.key);

    // remove loan from cache
    LoanModel.deleteById(this.loan.cacheId);

    // remove marketItemData from nft
    NftModel.save(this.nft.nftId, {
      marketItemData: undefined,
      owner: newOwner,
    });

    // transfer the nft from the owner to the buyer
    NftsModule.updateNftFromCache(
      this.owner,
      this.nft.collection,
      this.nft.tokenId,
      this.nft.chainId,
      {
        owner: newOwner,
        isDeposited: createsANewLoan,
      }
    );

    // add a new loan for the buyer if it was bought using debt
    // TODO
  }

  private validateId() {
    if (!this.id) {
      throw new Error("Market item id is not defined");
    }
  }

  get rarity(): Promise<bigint> {
    return new Promise<bigint>(async (resolve) => {
      const attributes = await this.nft.attributes;

      if (attributes.length === 0) {
        resolve(BigInt(0));
      } else {
        const rarities = await Promise.all(
          attributes.map((attribute) => attribute.rarity)
        );

        resolve(rarities.reduce((acc, rarity) => acc + rarity, BigInt(0)));
      }
    });
  }
}
