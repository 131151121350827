import { multiLoanBorrowValues } from "@unlockdfinance/unlockd-math";
import parseNftsToUseMaths from "../nfts/parseNftsToUseMaths";

export const calculateLoanValuation = (
  nfts: { valuation: bigint; ltv: bigint }[]
): bigint => {
  const _nfts = nfts.filter((nft) => nft.valuation > BigInt(0));

  return _nfts.length === 0
    ? BigInt(0)
    : BigInt(multiLoanBorrowValues(parseNftsToUseMaths(nfts)).valuation);
};
