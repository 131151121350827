import { Address } from "viem";

export class Bid {
  bidder: Address;
  amountToPay: bigint;
  amountOfDebt: bigint;

  constructor(bidder: Address, amountToPay: bigint, amountOfDebt: bigint) {
    this.bidder = bidder;
    this.amountToPay = amountToPay;
    this.amountOfDebt = amountOfDebt;
  }

  get amount(): bigint {
    return this.amountToPay + this.amountOfDebt;
  }
}
