import { SupportedChainIds } from "clients/verisModule";
import { ClientError, ServerError, UnexpectedError } from "errors";
import { AttributeFromReservoir } from "logic/helpers/nfts/getAttributesDataFromReservoir.server";
import { getNftId } from "logic/helpers/nfts/nftId";
import { SimpleNft } from "logic/types/nft/INft";

class ReservoirService {
  private attributesFromReservoirPromises: Map<
    string,
    Promise<AttributeFromReservoir[]>
  > = new Map();
  async getAttributes(input: SimpleNft & { chainId: SupportedChainIds }, forceUpdate: boolean = false) {
    const nftId = getNftId(input.collection, input.tokenId, input.chainId);

    const promise = this.attributesFromReservoirPromises.get(
      input.chainId + nftId
    );

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = new Promise<AttributeFromReservoir[]>(
      async (resolve, reject) => {
        const url = `/api/chain/${input.chainId}/collections/${input.collection}/tokens/${input.tokenId}/attributes`;

        const response = await fetch(url);

        if (!response.ok) {
          const body = await response.text();

          if (response.status > 499) reject(new ServerError(body));
          else if (response.status > 399) reject(new ClientError(body));

          reject(new UnexpectedError(body));
        } else {
          resolve((await response.json()) as AttributeFromReservoir[]);
        }

      }
    );

    this.attributesFromReservoirPromises.set(input.chainId + nftId, newPromise);

    try {
      return await newPromise;
    } catch (error) {
      this.attributesFromReservoirPromises.delete(nftId);

      throw error;
    }
  }
}

export default new ReservoirService();
