import { Address } from "@unlockdfinance/verislabs-web3";
import { SupportedChainIds, verisModule } from "clients/verisModule";
import {
  auctionContract,
  marketContract,
  uTokenFactoryContract,
} from "contracts";
import { ERC721Contract } from "contracts/ERC721Contract";
import { getNftId } from "logic/helpers/nfts/nftId";
import RedeemData from "logic/types/loan/RedeemData";

class ContractsService {
  private currentAmountPromises: Map<string, Promise<bigint>> = new Map();
  private ownerPromises: Map<string, Promise<Address>> = new Map();
  private redeemDataPromises: Map<string, Promise<RedeemData>> = new Map();
  private minBidPriceAuctionPromises: Map<string, Promise<bigint>> = new Map();
  private minBidPriceMarketPromises: Map<string, Promise<bigint>> = new Map();
  private totalSupplyPromises: Map<string, Promise<bigint>> = new Map();

  async getCurrentBorrowFromLoan(
    loanId: Address,
    underlyingAsset: Address,
    chainId: SupportedChainIds,
    forceUpdate = false,
  ): Promise<bigint> {
    const promise = this.currentAmountPromises.get(chainId + loanId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = uTokenFactoryContract.getDebtFromLoanId(
      underlyingAsset,
      loanId,
      chainId
    );

    this.currentAmountPromises.set(chainId + loanId, newPromise);

    try {
      return await newPromise;
    } catch (err) {
      this.currentAmountPromises.delete(loanId);

      throw err;
    }
  }

  async getNftOwner(
    collection: Address,
    tokenId: string,
    contract: ERC721Contract,
    forceUpdate: boolean = false,
    chainId: SupportedChainIds
  ) {
    const nftId = getNftId(collection, tokenId, chainId);

    const promise = this.ownerPromises.get(chainId + nftId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = contract.ownerOf(BigInt(tokenId), chainId);

    this.ownerPromises.set(chainId + nftId, newPromise);

    try {
      return await newPromise;
    } catch (err) {
      this.ownerPromises.delete(nftId);

      throw err;
    }
  }

  async getRedeemData(
    id: Address,
    assetIds: Address[],
    chainId: SupportedChainIds,
    forceUpdate: boolean = false,
  ): Promise<RedeemData> {
    const promiseId = id + assetIds.join("");

    const promise = this.redeemDataPromises.get(chainId + promiseId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = new Promise<RedeemData>(async (resolve) => {
      const [totalAmount, totalDebt, bidderBonus] =
        await auctionContract.getAmountToReedem(id, assetIds, chainId);

      resolve(new RedeemData(totalAmount, totalDebt, bidderBonus));
    });

    this.redeemDataPromises.set(chainId + promiseId, newPromise);

    try {
      return await newPromise;
    } catch (error) {
      this.redeemDataPromises.delete(promiseId);
      throw error;
    }
  }

  async getMinBidPriceAuction(
    loanId: Address,
    assetId: Address,
    nftValuation: bigint,
    aggValuation: bigint,
    aggLtv: bigint,
    chainId: SupportedChainIds,
    forceUpdate: boolean = false,
  ) {
    const promiseId = loanId + assetId + nftValuation + aggValuation + aggLtv;

    const promise = this.minBidPriceAuctionPromises.get(chainId + promiseId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = auctionContract.getMinBidPriceAuction(
      loanId,
      assetId,
      nftValuation,
      aggValuation,
      aggLtv,
      chainId
    );

    this.minBidPriceAuctionPromises.set(chainId + promiseId, newPromise);

    try {
      return await newPromise;
    } catch (error) {
      this.minBidPriceAuctionPromises.delete(promiseId);
      throw error;
    }
  }

  async getMinBidPriceMarket(
    id: Address,
    underlyingAsset: Address,
    aggValuation: bigint,
    aggLtv: bigint,
    chainId: SupportedChainIds,
    forceUpdate: boolean = false,
  ): Promise<bigint> {
    const promise = this.minBidPriceMarketPromises.get(chainId + id);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = marketContract.getMinBidPrice(
      id,
      underlyingAsset,
      aggValuation,
      aggLtv,
      chainId
    );

    this.minBidPriceMarketPromises.set(chainId + id, newPromise);

    try {
      return await newPromise;
    } catch (error) {
      this.minBidPriceMarketPromises.delete(id);

      throw error;
    }
  }

  async totalSupply(
    collectionAddress: Address,
    chainId: SupportedChainIds,
    forceUpdate: boolean = false,
  ): Promise<bigint> {
    const promise = this.totalSupplyPromises.get(chainId + collectionAddress);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = new ERC721Contract(collectionAddress).totalSupply(
      chainId
    );

    this.totalSupplyPromises.set(chainId + collectionAddress, newPromise);

    try {
      return await newPromise;
    } catch (error) {
      this.totalSupplyPromises.delete(collectionAddress);
      throw error;
    }
  }
}

export default new ContractsService();
