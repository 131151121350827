export default class RedeemData {
  totalAmount: bigint;
  totalDebt: bigint;
  bidderBonus: bigint;

  constructor(totalAmount: bigint, totalDebt: bigint, bidderBonus: bigint) {
    this.totalAmount = totalAmount;
    this.totalDebt = totalDebt;
    this.bidderBonus = bidderBonus;
  }
}
