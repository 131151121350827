import { Address } from "@unlockdfinance/verislabs-web3";
import { ethers } from "ethers";

export default function createAssetId(
  collection: Address,
  tokenId: string
): Address {
  return ethers.utils.solidityKeccak256(
    ["address", "uint256"],
    [collection, tokenId]
  ) as Address;
}
