export default class NftPrices {
  valuation: bigint;
  ltv: bigint;
  liquidationThreshold: bigint;

  constructor(valuation: bigint, ltv: bigint, liquidationThreshold: bigint) {
    this.valuation = valuation;
    this.ltv = ltv;
    this.liquidationThreshold = liquidationThreshold;
  }
}
