import { multiLoanBorrowValues } from "@unlockdfinance/unlockd-math";
import parseNftsToUseMaths from "../nfts/parseNftsToUseMaths";

export const calculateLtv = (
  nfts: {
    valuation: bigint;
    ltv: bigint;
  }[]
): bigint => {
  const _nfts = nfts.filter(
    (nft) => nft.ltv > BigInt(0) && nft.valuation > BigInt(0)
  );

  return _nfts.length === 0
    ? BigInt(0)
    : BigInt(multiLoanBorrowValues(parseNftsToUseMaths(nfts)).ltv);
};
