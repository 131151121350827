import { Schema } from "./Schema";
import { GetPropertiesType, GetTypesFromSchemaProperties } from "./types";

const collections: Record<
  string,
  (GetTypesFromSchemaProperties<GetPropertiesType<{}>> & { id: string })[]
> = {};

export default function model<T extends GetPropertiesType<T>>(
  name: string,
  schema: Schema<T>
) {
  if (!name.trim().length) throw new Error("name is empty or blank");

  collections[name] = [];

  return class Model {
    document: GetTypesFromSchemaProperties<T> & { id: string };

    constructor(document: GetTypesFromSchemaProperties<T> & { id: string }) {
      this.document = document;

      for (const [key, value] of Object.entries(schema.properties)) {
        // @ts-ignore
        if (value.default !== undefined && this.document[key] === undefined) {
          // @ts-ignore
          this.document[key] = value.default;
        }

        // @ts-ignore
        if (value.required && this.document[key] === undefined) {
          throw new Error(`${key} is required but not present`);
        }
      }

      this.document = document;

      for (const property in schema.properties) {
        this.document[property] = document[property];
        Object.defineProperty(this, property, {
          set(value) {
            this.document[property] = value;
          },

          get() {
            return this.document[property];
          },
        });
      }
    }

    static create(document: GetTypesFromSchemaProperties<T> & { id: string }) {
      return new Model({ ...document, id: document.id.toLowerCase() }).save();
    }

    async save() {
      const collection = collections[
        name
      ] as (GetTypesFromSchemaProperties<T> & { id: string })[];

      const index = collection.findIndex(
        ({ id }) => id.toLowerCase() === this.document.id.toLowerCase()
      );

      if (index < 0) {
        collection.push(this.document);
      } else {
        collection[index] = this.document;
      }

      collections[name] = collection;

      return this;
    }

    static findById(id: string) {
      const _id = id.toLowerCase();

      const collection = collections[
        name
      ] as (GetTypesFromSchemaProperties<T> & { id: string })[];

      const document = collection.find((document) => document.id === _id);

      return document ? new Model({ ...document }) : null;
    }

    static deleteById(id: string) {
      const collection = collections[
        name
      ] as (GetTypesFromSchemaProperties<T> & { id: string })[];

      const index = collection.findIndex(
        (document) => document.id === id.toLowerCase()
      );

      if (index < 0) return false;

      collection.splice(index, 1);

      collections[name] = collection;

      return true;
    }

    static findByIdLeanNullSafe(id: string) {
      const collection = collections[
        name
      ] as (GetTypesFromSchemaProperties<T> & { id: string })[];

      const document = collection.find(
        (document) => document.id === id.toLowerCase()
      );

      return { ...document } as GetTypesFromSchemaProperties<T> & {
        id: string;
      };
    }

    /**
     * @deprecated .P
     */
    static getProperty<TProperty extends keyof GetTypesFromSchemaProperties<T>>(
      id: string,
      propertyName: TProperty
    ): (GetTypesFromSchemaProperties<T> & { id: string })[TProperty] | null {
      return this.findById(id.toLowerCase())?.document[propertyName] || null;
    }

    static save(id: string, data: Partial<GetTypesFromSchemaProperties<T>>) {
      const _id = id.toLowerCase();

      const nftFromStore = this.findById(_id);

      if (!nftFromStore) {
        const _data = data;

        for (const [key, value] of Object.entries(schema.properties)) {
          // @ts-ignore
          if (value.default !== undefined && _data[key] === undefined) {
            // @ts-ignore
            _data[key] = value.default;
          }

          // @ts-ignore
          if (value.required && _data[key] === undefined) {
            throw new Error(`${key} is required but not present`);
          }

          // @ts-ignore
          if (_data[key] === undefined) {
            // @ts-ignore
            _data[key] = undefined;
          }
        }

        this.create({
          id: _id,
          ...(_data as GetTypesFromSchemaProperties<T>),
        });
      } else {
        for (const [key, value] of Object.entries(data)) {
          // @ts-ignore
          nftFromStore.document[key] = value;
        }

        nftFromStore.save();
      }
    }
  };
}

// const schema = new Schema({
//   name: { type: String, required: false },
//   age: { type: Number, required: false },
// } as const);

// const User = model("user", schema);

// const user = new User({ id: "s", name: "yeap", age: undefined });
