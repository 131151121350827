import NftErc721 from "./NftErc721";
import { faker } from "@faker-js/faker";
import { Address } from "@unlockdfinance/verislabs-web3";
import { INft } from "./INft";
import { NftOptions } from "./Nft";
import { SupportedChainIds, verisModule } from "clients/verisModule";

export default class NftFactory {
  static create({
    collection,
    tokenId,
    chainId,
    isDeposited,
    options,
  }: {
    collection: Address;
    tokenId: string;
    chainId: SupportedChainIds;
    isDeposited: boolean;
    options?: NftOptions;
  }): INft {
    return new NftErc721(collection, tokenId, chainId, isDeposited, options);
  }

  // static createRandomNft(): INft {
  //   const _collection =
  //     verisModule.currentNetworkConfig.COLLECTIONS[
  //     faker.number.int({
  //       min: 0,
  //       max: verisModule.currentNetworkConfig.COLLECTIONS.length - 1,
  //     })
  //     ];

  //   const collection = _collection.address;
  //   const tokenId = faker.number.int({ min: 1, max: 1000 }).toString();
  //   const isDeposited = faker.datatype.boolean();

  //   return this.create({
  //     collection,
  //     tokenId,
  //     options: { owner: "0x" },
  //     isDeposited,
  //   });
  // }
}
