import { healthFactor } from "@unlockdfinance/unlockd-math";

export type HealthFactorVariations = { debt?: bigint; collateral?: bigint };

export const calculateHealthFactor = (
  currentCollateral: bigint,
  currentDebt: bigint,
  liquidationThreshold: bigint,
  variations?: HealthFactorVariations
): bigint => {
  const result = healthFactor({
    collateral: currentCollateral,
    liquidationThreshold: liquidationThreshold,
    debt: currentDebt,
    variations,
  });

  return BigInt(result.toString());
};

// Data to test
// Price: 100ETH
// LiqT: 0.85
// LTV: 0.6
// Loan: 60ETH
// HF: 1.42
// LiqPx: 71ETH
