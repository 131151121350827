import { Address } from "viem";
import { MarketItemAuctionStatus } from "../../types/marketplace/MarketItem";
import { MarketItemType } from "../../../contracts/MarketContract";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";

export const determineAuctionStatus = (
  type: MarketItemType,
  owner: Address,
  addressFromWallet: Address | null,
  biddingEnd: number,
  latestBidder: Address | null,
  hasBids: boolean
): MarketItemAuctionStatus | null => {
  if (type === MarketItemType.TYPE_FIXED_PRICE) return null;

  let status: MarketItemAuctionStatus;

  if (addressFromWallet && equalIgnoreCase(owner, addressFromWallet)) {
    if (type === MarketItemType.TYPE_LIQUIDATION_AUCTION) {
      if (biddingEnd && Date.now() - biddingEnd < 0) {
        if (!hasBids) {
          status = MarketItemAuctionStatus.TO_REPAY;
        } else {
          status = MarketItemAuctionStatus.TO_REDEEM;
        }
      } else {
        status = MarketItemAuctionStatus.REDEEM_FINISHED;
      }
    } else {
      status = MarketItemAuctionStatus.BID_FINISHED;
    }
  } else if (!latestBidder && Date.now() - biddingEnd < 0) {
    status = MarketItemAuctionStatus.TO_FIRST_BID;
  } else if (
    addressFromWallet &&
    latestBidder &&
    equalIgnoreCase(latestBidder, addressFromWallet)
  ) {
    if (Date.now() - biddingEnd < 0) {
      status = MarketItemAuctionStatus.BIDDED;
    } else {
      status = MarketItemAuctionStatus.TO_CLAIM;
    }
  } else if (Date.now() - biddingEnd < 0) {
    status = MarketItemAuctionStatus.TO_BID;
  } else {
    status = MarketItemAuctionStatus.BID_FINISHED;
  }

  return status;
};
