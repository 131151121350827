import { TheGraph } from "data/TheGraphService";
import { Bid } from "../types/marketplace/Bid";

export default function parseAndSortBidsFromSubgraph(
  bidsFromSubgraph: TheGraph.BidFromSubgraph[]
): Bid[] {
  return bidsFromSubgraph
    .map(
      (bid) =>
        new Bid(bid.bidder, BigInt(bid.amountToPay), BigInt(bid.amountOfDebt))
    )
    .sort((a, b) => Number(a.amount) - Number(b.amount));
}
