import { SimpleNft } from "logic/types/nft/INft";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import createAssetId from "../createAssetId";
import { TheGraph, TheGraphService } from "data/TheGraphService";
import { SupportedChainIds } from "clients/verisModule";

export default async function areNftsDeposited(
  nfts: SimpleNft[],
  chainId: SupportedChainIds
): Promise<boolean[]> {
  const assetIds = await Promise.all(
    nfts.map(({ collection, tokenId }) => createAssetId(collection, tokenId))
  );

  const { assets } = await TheGraphService.get(chainId).client.getLoansFromAssetIds({
    status: TheGraph.LoanStatus.BORROWED,
    assetIds,
  });

  if (!assets) {
    throw new Error(`Subgraph error`);
  }

  return assetIds.map((assetId) => {
    const index = assets.findIndex((asset: { id: string }) =>
      equalIgnoreCase(asset.id, assetId)
    );

    return index !== -1;
  });
}
