//@ts-nocheck

const queues = {}

function queue(key, callback, delay = 0) {
    const queue = queues[key] || (queues[key] = Promise.resolve())

    return queues[key] = (delay ? queue.then(() => new Promise(resolve => setTimeout(resolve, delay))) : queue).then(callback)
}

export default queue