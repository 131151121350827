import { Address } from "@unlockdfinance/verislabs-web3";
import MarketItemData from "logic/types/nft/MarketItemData";
import { MarketItemType } from "contracts/MarketContract";
import parseAndSortBidsFromSubgraph from "logic/helpers/parseAndSortBidsFromSubgraph";
import { parseEndTime } from "logic/helpers/parseEntTime";
import { SupportedChainIds } from "clients/verisModule";
import { getBuiltGraphSDK } from ".graphclient";
import { AsyncReturnType } from "logic/types/AsyncReturnType";

export namespace TheGraph {
  export enum LoanStatus {
    BORROWED = "0",
    PENDING = "1",
    PAID = "2",
  }

  export type Loan = NonNullable<
    AsyncReturnType<
      InstanceType<typeof TheGraphService>["client"]["getLoanById"]
    >["loan"]
  >;

  export enum MarketItemStatus {
    ACTIVE = "0",
    CANCELLED = "1",
    REDEEMED = "2",
    BOUGHT = "3",
    CLAIMED = "4",
    PAID = "5",
  }

  export type BidFromSubgraph = NonNullable<
    AsyncReturnType<
      InstanceType<typeof TheGraphService>["client"]["getMarketItem"]
    >["order"]
  >["bids"][0];

  export type MarketItem = NonNullable<
    AsyncReturnType<
      InstanceType<typeof TheGraphService>["client"]["getMarketItem"]
    >["order"]
  >;
}

export class TheGraphService {
  static instances: Map<SupportedChainIds, TheGraphService> = new Map();

  static get(chainId: SupportedChainIds) {
    let instance = this.instances.get(chainId);

    if (!instance) {
      instance = new TheGraphService(chainId);
      this.instances.set(chainId, instance);
    }

    return instance;
  }

  private marketItemFromNftPromises = new Map<
    string,
    Promise<MarketItemData | null>
  >();

  client: ReturnType<typeof getBuiltGraphSDK>;

  constructor(chain: SupportedChainIds) {
    this.client = getBuiltGraphSDK({
      chainName: this.getTheGraphChainName(chain),
    });
  }

  removeMarketItemFromNftPromise(assetId: Address) {
    this.marketItemFromNftPromises.delete(assetId);
  }

  async getMarketItemFromNft(assetId: Address, forceUpdate: boolean = false) {
    const promise = this.marketItemFromNftPromises.get(assetId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = new Promise<MarketItemData | null>(async (resolve) => {
      const { orders } = await this.client.getMarketItemsFromNfts({
        assetIds: [assetId],
      });

      if (orders.length === 0) {
        resolve(null);
      } else {
        const order = orders[0];

        resolve(
          new MarketItemData(
            order.id as Address,
            parseEndTime(order.endTime),
            order.orderType as MarketItemType,
            parseAndSortBidsFromSubgraph(order.bids)
          )
        );
      }
    });

    this.marketItemFromNftPromises.set(assetId, newPromise);

    try {
      return await newPromise;
    } catch (error) {
      this.marketItemFromNftPromises.delete(assetId);
      throw error;
    }
  }

  private getTheGraphChainName(chain: SupportedChainIds) {
    switch (chain) {
      case 1:
        return "ethereum-mainnet";
      case 11155111:
        return "ethereum-sepolia";
      case 80002:
        return "polygon-amoy";
      default:
        throw new Error("Unsupported chain");
    }
  }
}
