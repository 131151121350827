import { Nft, NftOptions } from "./Nft";
import {
  Address,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import { SupportedChainIds, externalWalletModule } from "clients/verisModule";
import unlockdWalletModule from "logic/UnlockdWalletModule";
import NftsModule from "logic/NftsModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";

export default class NftErc721 extends Nft {
  constructor(
    collection: Address,
    tokenId: string,
    chainId: SupportedChainIds,
    isDeposited: boolean,
    options?: NftOptions
  ) {
    super(collection, tokenId, chainId, isDeposited, options);
  }

  async transfer(
    from: Address,
    to: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    const extWallet = externalWalletModule.address!;
    const unlockdAddress = unlockdWalletModule.unlockdAddress;

    let output: Output<void>;

    if (unlockdAddress && equalIgnoreCase(from, unlockdAddress)) {
      output = await unlockdWalletModule.withdrawAssets([this], to, options);
    } else {
      output = await this._collection.contract.transferFrom(
        from,
        to,
        BigInt(this.tokenId),
        options
      );
    }

    Nft.model.save(this.nftId, { owner: to });

    NftsModule.removeNftFromCache(from, this.collection, this.tokenId, this.chainId);

    if (to === extWallet || to === unlockdAddress) {
      NftsModule.addNftToCache(to, {
        collection: this.collection,
        tokenId: this.tokenId,
        chainId: this.chainId,
        isDeposited: this.isDeposited,
        owner: to,
      });
    }

    return output;
  }
}
