import { percentMul } from "../../../utils";

export const calculateAvailableToBorrowByNft = (
  valuation: bigint,
  ltv: bigint
) => {
  if (valuation === BigInt(0) || ltv === BigInt(0)) return BigInt(0);

  return percentMul(valuation, ltv);
};
