import { Address } from "@unlockdfinance/verislabs-web3";
import { Nft as AlchemyNft } from "alchemy-sdk";
import { ConnectionError } from "errors";
import { getNftFromNftId } from "logic/helpers/nfts/nftId";
import { ERC721Contract } from "contracts/ERC721Contract";
import { Attribute } from "./Attribute";
import { NftModel } from "data/store/models";
import alchemyService from "data/AlchemyService";
import { SupportedChainIds, verisModule } from "clients/verisModule";
import queue from "../../../utils/queue";

class NftsMetadataModule {
  defaultImage: string;

  constructor(defaultImage: string) {
    this.defaultImage = defaultImage;
  }

  async getNftImage(nftId: string): Promise<string> {
    return queue('getNftImage', async () => {
      try {
        let image: string;

        const { image: nftImage } = NftModel.findByIdLeanNullSafe(nftId);

        if (nftImage) {
          image = nftImage as string;
        } else {
          const { collection, tokenId, chain } = getNftFromNftId(nftId);

          const alchemyNft = await alchemyService.getNftMetadata(
            collection,
            tokenId,
            chain,
          );

          const { cachedUrl } = alchemyNft.image;

          const attributes = this.parseAttributesFromAlchemyNft(alchemyNft, chain);

          if (attributes.length > 0) {
            NftModel.save(nftId, { attributes });
          }

          if (alchemyNft.name) {
            NftModel.save(nftId, { title: alchemyNft.name });
          }

          if (cachedUrl) {
            image = cachedUrl;
          } else {
            let tokenUri: string;

            const _tokenUri = alchemyNft.tokenUri;

            if (_tokenUri) {
              tokenUri = _tokenUri;
            } else {
              tokenUri = await this.getTokenUriOnChain(collection, tokenId, chain);
            }

            image = await this.fetchTokenUri(tokenUri);
          }
        }

        NftModel.save(nftId, { image });

        return image;
      } catch (err) {
        console.error(err);

        return this.defaultImage;
      }
    }, 100)
  }

  // async getNftTitle2(nftId: string): Promise<string> {
  //   try {
  //     const { collection, tokenId, chain } = getNftFromNftId(nftId);

  //     let tokenUri: string;

  //     tokenUri = await this.getTokenUriOnChain(collection, tokenId, chain);

  //     let brandModel: string;

  //     brandModel = await this.fetchTokenUri2(tokenUri);

  //     return brandModel;
  //   } catch (err) {
  //     console.error(err);

  //     return 'wtf brand';
  //   }
  // }

  async getNftAttributes(nftId: string): Promise<Attribute[]> {
    try {
      const { attributes: nftAttributes } =
        NftModel.findByIdLeanNullSafe(nftId);

      if (nftAttributes) {
        return nftAttributes;
      }

      const { collection, tokenId, chain } = getNftFromNftId(nftId);

      const alchemyNft = await alchemyService.getNftMetadata(
        collection,
        tokenId,
        chain
      );

      if (alchemyNft.image.cachedUrl) {
        NftModel.save(nftId, { image: alchemyNft.image.cachedUrl });
      }

      if (alchemyNft.name) {
        NftModel.save(nftId, { title: alchemyNft.name });
      }

      const attributes = this.parseAttributesFromAlchemyNft(alchemyNft, chain);

      NftModel.save(nftId, { attributes });

      return attributes;
    } catch (err) {
      console.error(err);

      return [];
    }
  }

  async getNftTitle(nftId: string): Promise<string | undefined> {
    const { title: nftTitle } = NftModel.findByIdLeanNullSafe(nftId);

    if (nftTitle) {
      return nftTitle as string;
    }

    const { collection, tokenId, chain } = getNftFromNftId(nftId);

    const alchemyNft = await alchemyService.getNftMetadata(collection, tokenId, chain);

    if (alchemyNft.image.cachedUrl) {
      NftModel.save(nftId, { image: alchemyNft.image.cachedUrl });
    }

    const attributes = this.parseAttributesFromAlchemyNft(alchemyNft, chain);

    if (attributes.length > 0) {
      NftModel.save(nftId, { attributes });
    }

    if (alchemyNft.name) {
      NftModel.save(nftId, { title: alchemyNft.name });

      return alchemyNft.name;
    }
  }

  private parseAttributesFromAlchemyNft(alchemyNft: AlchemyNft, chainId: SupportedChainIds): Attribute[] {
    const attributes: Attribute[] = [];

    if (alchemyNft.raw.metadata?.attributes) {
      for (const [key, value] of Object.entries(
        alchemyNft.raw.metadata.attributes
      )) {
        attributes.push(
          new Attribute(
            {
              collection: alchemyNft.contract.address as Address,
              tokenId: alchemyNft.tokenId,
              chainId,
            },
            key,
            value as string
          )
        );
      }
    }

    return attributes;
  }

  private async getTokenUriOnChain(collection: Address, tokenId: string, chainId: SupportedChainIds) {
    const contract = new ERC721Contract(collection);

    return contract.tokenUri(BigInt(tokenId), chainId);
  }

  // private async getNftImageFromTokenUri(
  //   collection: Address,
  //   tokenId: number,
  //   tokenUri: string
  // ) {
  //   const collectionStore = this.imagesStore.get(collection);

  //   if (collectionStore) {
  //     const image = collectionStore.get(tokenId);

  //     if (image) {
  //       return this.parseUrl(image);
  //     }
  //   }

  //   try {
  //     return await this.fetchTokenUri(tokenUri);
  //   } catch (err) {
  //     console.log(err);
  //     return this.defaultImage;
  //   }
  // }

  private async fetchTokenUri(tokenUri: string): Promise<string> {
    const response = await fetch(this.parseUrl(tokenUri));

    if (!response.ok) {
      throw new ConnectionError("failed on fetching ipfs image");
    }

    const { image }: { image: string } = await response.json();

    return this.parseUrl(image);
  }

  // private async fetchTokenUri2(tokenUri: string): Promise<string> {
  //   const response = await fetch(`/api/metadata?tokenUri=${tokenUri}`);

  //   if (!response.ok) {
  //     throw new ConnectionError("failed on fetching ipfs brand");
  //   }

  //   const { brand, name }: { brand: string, name: string } = await response.json();

  //   return name || brand;
  // }

  private parseUrl(url: string): string {
    if (url.startsWith("ipfs")) {
      if (verisModule.isMainnetConnected) {
        return "https://ipfs.io/ipfs/" + url.substring(7);
      } else {
        return "https://cloudflare-ipfs.com/ipfs/" + url.substring(7);
      }
    } else if (
      !verisModule.isMainnetConnected &&
      url.startsWith("https://ipfs.io")
    ) {
      return url.replace("https://ipfs.io", "https://cloudflare-ipfs.com");
    }

    return url;
  }
}

export default new NftsMetadataModule(
  "https://i.seadn.io/gae/9ZLCKS7w0XtwLpq7QVMRgY4Eakg5CQaLa52HFcf4HKksqOxRKc_ybis58FWnjHcNKf5MHc_Iw_JAP_7WPMJFLBLOkhFhBd4HU0InJw"
);
