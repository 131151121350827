import { Address } from "@unlockdfinance/verislabs-web3";
import { MarketItemType } from "contracts/MarketContract";
import { Bid } from "../marketplace/Bid";

export default class MarketItemData {
  id: Address;
  biddingEnd: number;
  type: MarketItemType;
  bids: Bid[];

  constructor(
    id: Address,
    biddingEnd: number,
    type: MarketItemType,
    bids: Bid[]
  ) {
    this.id = id;
    this.biddingEnd = biddingEnd;
    this.type = type;
    this.bids = bids;
  }
}
