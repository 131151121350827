import { AssetTransfersCategory, SortingOrder } from "alchemy-sdk";
import { Address } from "viem";
import { Hash } from "@unlockdfinance/verislabs-web3";
import { SupportedChainIds, verisModule } from "clients/verisModule";
import Chain from "logic/types/chain/Chain";

export interface ITransaction {
  hash: Hash;
  status?: number;
  chain: Chain;
  date?: Date;
}

export const retrieveMintHistoryOfAddress = async (
  address: Address,
  chainId: SupportedChainIds
): Promise<ITransaction[]> => {
  const faucetAddresses =
    verisModule.getNetwork(chainId).FAUCET_COLLECTIONS.map(
      ({ address }) => address
    );

  const res = await verisModule
    .getAlchemyClient(chainId)
    .core.getAssetTransfers({
      fromBlock: "0x0",
      fromAddress: "0x0000000000000000000000000000000000000000",
      toAddress: address,
      contractAddresses: faucetAddresses,
      excludeZeroValue: true,
      category: [AssetTransfersCategory.ERC721],
      order: SortingOrder.DESCENDING,
      withMetadata: true,
    });

  const transfers = res.transfers.map((transfer) => {
    return {
      hash: transfer.hash as Hash,
      date: new Date(transfer.metadata.blockTimestamp),
      status: 1,
      chain: Chain.get(chainId),
    };
  });

  return transfers.filter((transfer, index, array) => {
    return (
      array.findIndex((_transfer) => _transfer.hash === transfer.hash) === index
    );
  });
};
