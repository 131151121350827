import { NetworkConfig, app } from "config/app.config";
import { Erc721Collection } from "./types/collection/Collection";
import currenciesModule from "./CurrenciesModule";
import { Address } from "@unlockdfinance/verislabs-web3";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { SupportedChainIds } from "clients/verisModule";

class CollectionModule {
  private collectionsForNetwork: Map<SupportedChainIds, Erc721Collection[]> =
    new Map();

  constructor(networks: NetworkConfig[]) {
    networks.forEach((network) => {
      this.collectionsForNetwork.set(
        network.CHAIN.id,
        network.COLLECTIONS.map((collectionData) => {
          const currenciesSupported = collectionData.currenciesSupported.map(
            ({ address }) =>
              currenciesModule.getErc20CurrencyByAddress(
                address,
                network.CHAIN.id
              )!
          );

          return new Erc721Collection({
            ...collectionData,
            currenciesSupported,
            chainId: network.CHAIN.id,
          });
        })
      );
    });
  }

  getCollections(
    chainId: SupportedChainIds
  ): Erc721Collection[] {
    const collections = this.collectionsForNetwork.get(chainId);

    if (!collections) {
      throw new Error(
        `Collections not found for chain ${chainId}`
      );
    }

    return collections;
  }

  getCollectionByAddress(address: Address, chainId: SupportedChainIds) {
    const collection = this.getCollections(chainId).find((collection) =>
      equalIgnoreCase(collection.address, address)
    );

    if (!collection) {
      throw new Error("Collection not found");
    }

    return collection;
  }

  getCollectionsByCurrencyAddress(
    currencyAddress: Address,
    chainId: SupportedChainIds
  ) {
    return this.getCollections(chainId).filter((collection) =>
      collection.currenciesSupported.some((currency) =>
        equalIgnoreCase(currency.address, currencyAddress)
      )
    );
  }
}

export default new CollectionModule(app.ELEGIBLE_NETWORKS);
