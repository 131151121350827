import { Address } from "viem";
import { customERC721Abi } from "./abis/customERC721";
import { WalletType } from "./types";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";
import { SupportedChainIds, externalWalletModule } from "clients/verisModule";

export class ERC721Contract extends AbstractContract<typeof customERC721Abi> {
  genericImage?: string;
  uBoundAddress?: Address;

  constructor(address: Address, genericImage?: string) {
    super({ address }, customERC721Abi);

    this.genericImage = genericImage;
  }

  async balanceOf(
    address: Address,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.balanceOf([address]);
  }

  async ownerOf(
    tokenId: bigint,
    chainId: SupportedChainIds
  ): Promise<Address> {
    return (await this.viemReadContract(chainId)).read.ownerOf([tokenId]);
  }

  async maxSupply(chainId: SupportedChainIds): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.MAX_SUPPLY();
  }

  async totalSupply(chainId: SupportedChainIds): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.totalSupply();
  }

  async isApprovedForAll(
    sender: Address,
    operator: Address,
    chainId: SupportedChainIds
  ): Promise<boolean> {
    return (await this.viemReadContract(chainId)).read.isApprovedForAll([
      sender,
      operator,
    ]);
  }

  async tokenUri(tokenId: bigint, chainId: SupportedChainIds) {
    return (await this.viemReadContract(chainId)).read.tokenURI([tokenId]);
  }

  async transferFrom(
    from: Address,
    to: Address,
    tokenId: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.transferFrom([
          from,
          to,
          tokenId,
        ]),
      options
    );
  }

  mint(
    address: Address,
    amount: number,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.mint([address, amount]),
      options
    );
  }

  setApprovalForAll(
    address: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.setApprovalForAll([
          address,
          true,
        ]),
      options
    );
  }
}
