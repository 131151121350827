import { Attribute } from "logic/types/nft/Attribute";
import model from "./Model";
import { Schema } from "./Schema";
import NftPrices from "logic/types/nft/NftPrices";
import RedeemData from "logic/types/loan/RedeemData";
import MarketItemData from "logic/types/nft/MarketItemData";

const NftModel = model(
  "nft",
  new Schema({
    image: { type: String },
    attributes: { type: Array<Attribute> },
    title: { type: String },
    owner: { type: String },
    prices: { type: NftPrices, ttl: 1000 * 60 * 10 },
    lastSalePrice: { type: BigInt },
    marketItemData: { type: MarketItemData },
  })
);

const LoanModel = model(
  "loan",
  new Schema({
    nfts: { type: Array<string> },
    borrower: { type: String },
    currentBorrowAmount: { type: BigInt, ttl: 1000 * 60 * 10 },
    underlyingAsset: { type: String },
    lastSalePrice: { type: BigInt },
    redeemData: { type: RedeemData },
  })
);

const MarketItemModel = model(
  "marketItem",
  new Schema({
    reservoirBid: { type: BigInt },
    buyNowPrice: { type: BigInt },
    minBid: { type: BigInt },
  })
);

const AttributeModel = model(
  "attribute",
  new Schema({
    rarity: { type: BigInt },
    floorPrice: { type: BigInt },
  })
);

export { NftModel, LoanModel, MarketItemModel, AttributeModel };
