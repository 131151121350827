import { Address } from "@unlockdfinance/verislabs-web3";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { GetNftsForOwnerOptions, OwnedNft, SortingOrder } from "alchemy-sdk";
import { SupportedChainIds, verisModule } from "clients/verisModule";
import { getNftId } from "logic/helpers/nfts/nftId";
import Chain from "logic/types/chain/Chain";

class AlchemyService {
  private getAlchemy(chainId: SupportedChainIds) {
    return verisModule.getAlchemyClient(chainId);
  }

  private nftsMetadataPromises = new Map<
    string,
    ReturnType<ReturnType<typeof this.getAlchemy>["nft"]["getNftMetadata"]>
  >();

  private nftSalesPromises = new Map<
    string,
    ReturnType<ReturnType<typeof this.getAlchemy>["nft"]["getNftSales"]>
  >();

  private nftsForOwnerPromises = new Map<
    string,
    ReturnType<ReturnType<typeof this.getAlchemy>["nft"]["getNftsForOwner"]>
  >();

  async getNftMetadata(
    collection: Address,
    tokenId: string,
    chainId: SupportedChainIds,
    forceUpdate: boolean = false
  ) {
    const nftId = getNftId(collection, tokenId, chainId);

    const promise = this.nftsMetadataPromises.get(nftId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = this.getAlchemy(chainId).nft.getNftMetadata(
      collection,
      tokenId
    );

    this.nftsMetadataPromises.set(nftId, newPromise);

    try {
      return await newPromise;
    } catch (err) {
      this.nftsMetadataPromises.delete(nftId);

      throw err;
    }
  }

  async getNftSales(
    collection: Address,
    tokenId: string,
    chainId: SupportedChainIds,
    order: SortingOrder,
    forceUpdate: boolean = false
  ) {
    const nftId = getNftId(collection, tokenId, chainId);

    const promise = this.nftSalesPromises.get(nftId);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = this.getAlchemy(chainId).nft.getNftSales({
      contractAddress: collection,
      tokenId,
      order,
    });

    this.nftSalesPromises.set(nftId, newPromise);

    try {
      return await newPromise;
    } catch (err) {
      this.nftSalesPromises.delete(nftId);

      throw err;
    }
  }

  async getNftsForOwner(
    owner: Address,
    chainId: SupportedChainIds,
    options: GetNftsForOwnerOptions,
    forceUpdate: boolean = false
  ) {
    const promise = this.nftsForOwnerPromises.get(owner + Chain.get(chainId).name);

    if (promise && !forceUpdate) {
      return promise;
    }

    const newPromise = this.getAlchemy(chainId).nft.getNftsForOwner(owner, options);

    this.nftsForOwnerPromises.set(owner + Chain.get(chainId).name, newPromise);

    try {
      return await newPromise;
    } catch (err) {
      this.nftsForOwnerPromises.delete(owner);

      throw err;
    }
  }

  async getNftsForOwnerLoop(
    address: Address,
    chainId: SupportedChainIds,
    options: GetNftsForOwnerOptions,
    allTokens: boolean = false
  ): Promise<OwnedNft[]> {
    const ownedNfts: OwnedNft[] = [];

    const contractsGroupedByCall: Address[][] = [];

    options?.contractAddresses?.forEach((address, index) => {
      const arrayIndex = Math.floor(index / 20);

      if (contractsGroupedByCall[arrayIndex]) {
        contractsGroupedByCall[arrayIndex].push(address as Address);
      } else {
        contractsGroupedByCall[arrayIndex] = [address as Address];
      }
    });

    const call = async (callIndex: number): Promise<OwnedNft[]> => {
      const _options: GetNftsForOwnerOptions = {
        ...options,
      };

      if (contractsGroupedByCall.length) {
        _options.contractAddresses = contractsGroupedByCall[callIndex];
      }

      let { ownedNfts: _ownedNfts, pageKey } = await this.getNftsForOwner(
        address,
        chainId,
        _options
      );

      if (
        _options.contractAddresses !== undefined &&
        _options.contractAddresses.length > 0
      ) {
        _ownedNfts = _ownedNfts.filter((nft) =>
          _options.contractAddresses!.some((contractAddress) =>
            equalIgnoreCase(nft.contract.address, contractAddress)
          )
        );
      }

      ownedNfts.push(..._ownedNfts);

      if (allTokens && pageKey) {
        options.pageKey = pageKey;

        return call(callIndex);
      } else if (callIndex < contractsGroupedByCall.length - 1) {
        return call(callIndex++);
      }

      return ownedNfts;
    };

    return call(0);
  }
}

export default new AlchemyService();
