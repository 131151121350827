import { SupportedChainIds } from "clients/verisModule";
import { TheGraph, TheGraphService } from "data/TheGraphService";
import { Address } from "viem";

export default async function getLoansOfAddress(
  address: Address,
  loanStatus: TheGraph.LoanStatus,
  chainId: SupportedChainIds
) {
  const { accounts } = await TheGraphService.get(chainId).client.getLoansOfAddress({
    user: address,
    status: loanStatus,
  });

  if (!accounts || !accounts.length) return [];

  return accounts[0].borrows;
}
